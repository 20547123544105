import axios from "axios"

const initAxiosWithToken = async({token}) => {
    console.log(process.env.REACT_APP_BASE_URL)
    console.log(process.env.REACT_APP_BASE_URL2)
    console.log("Current environment:", process.env.NODE_ENV);
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    return 1
}

export default initAxiosWithToken