import React, {lazy, useEffect, useState} from 'react';
import './App.css';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import {themeChange} from 'theme-change';
import initializeApp from './app/init';
import {useAuth0} from '@auth0/auth0-react';
import initAxiosWithToken from './app/initAxiosWithToken';

// Importing pages
const Layout = lazy(() => import('./containers/Layout'));
const Login = lazy(() => import('./pages/Login'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const Register = lazy(() => import('./pages/Register'));
const Documentation = lazy(() => import('./pages/Documentation'));

// Initializing different libraries
initializeApp();

function App() {
    const {isLoading, error, isAuthenticated, getAccessTokenSilently} = useAuth0();
    const [isAxiosHeaderAdded, setIsAxiosHeaderAdded] = useState(false)

    useEffect(() => {
        // 👆 daisy UI themes initialization
        themeChange(false);
    }, []);

    useEffect(() => {
        // 👆 daisy UI themes initialization
        console.log(isAuthenticated)
        console.log("isAuthenticated")
        if (!isLoading) {
            if (isAuthenticated) {
                updateAxiosHeader()
                console.log("here coming....")
            } else {
                setIsAxiosHeaderAdded(true)
                console.log("load login")
            }
        }
    }, [isLoading]);

    const updateAxiosHeader = async () => {
        const token = await getAccessTokenSilently()
        // console.log(token)
        console.log("token generated-------")
        await initAxiosWithToken({token})
        setIsAxiosHeaderAdded(true)
    }

    if (error) {
        return <div>Oops... {error.message}</div>;
    }

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!isAxiosHeaderAdded) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <Router>
                <Routes>
                    {/* Redirect to /app/welcome if authenticated, otherwise show login */}
                    <Route path="/" element={isAuthenticated ? <Navigate to="/app/welcome" replace/> : <Login/>}/>
                    <Route path="/forgot-password" element={<ForgotPassword/>}/>
                    <Route path="/register" element={<Register/>}/>
                    <Route path="/documentation" element={<Documentation/>}/>

                    {/* Place new routes over this */}
                    <Route path="/app/*" element={isAuthenticated ? <Layout/> : <Navigate to="/" replace/>}/>

                    {/* Redirect to /app/welcome if authenticated, otherwise to login */}
                    <Route path="*" element={isAuthenticated ? <Navigate to="/app/welcome" replace/> :
                        <Navigate to="/" replace/>}/>
                </Routes>
            </Router>
        </>
    );
}

export default App;